/*CJM Styles*/
.cjm-page {

  .timeline-section {
    width: 100%;

    .timeline-item {

      @media screen and (max-width: 767px) {
        padding-left: 60px;
        padding-right: 20px;
      }

      .timeline-panel {
        @media screen and (max-width: 767px) {
          padding: 20px 20px;
        }

      }

    }

    .timeline-badge {
      width: 24px;
      height: 24px;
    }

    .timeline-badge.timeline-img {
      background-color: #db020a;
    }

    .timeline-badge {
      left: -12px;
      right: auto;

      @media screen and (max-width: 767px) {
        left: 13px;
        right: auto;
      }

    }

    .timeline-inverted .timeline-badge {
      left: auto;
      right: -12px;

      @media screen and (max-width: 767px) {
        left: 13px;
        right: auto;
      }

    }

    .sub-step-moment {
      color: #ffffff;
      font-size: 1rem;
      padding: 5px;
      position: relative;

      @media screen and (max-width: 767px) {
        font-size: 0.875rem;
      }

    }

    .timeline-panel-header {

      .timeline-left {
        @media screen and (max-width: 767px) {
          float: left;
          margin-right: 10px;
        }
      }

    }

    .timeline-body {
      margin-top: 50px;
      text-align: left;

      .accordion {
        box-shadow: none;

        .content-accordion-analisis-frecuencia {
          .MuiTable-root {
            width: 502px;
          }
        }

      }

      .MuiExpansionPanelSummary-root {
        background: #526069;
        color: #ffffff;
        border-radius: 5px;

        svg {
          fill: #ffffff;
        }
      }

      .MuiExpansionPanelDetails-root {
        padding: 0;

        .content-accordion {
          width: 100%;
        }
      }

    }

    .sub-step-punto-contacto {
      position: relative;
      top: 0px;
      left: -24px;
      background-color: #ffffff;
      font-size: 1rem;
      padding: 3px;

      @media screen and (max-width: 767px) {
        font-size: 0.875rem;
        margin-right: -12px;
      }

    }
  }

  .cell-impacto {
    padding: 50px 50px;
    width: 100%;
    display: block;
    font-weight : 500;
  }

  .MuiTableCell-footer {

    .MuiIconButton-root {
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }

    .MuiSelect-selectMenu.MuiSelect-selectMenu {
      @media screen and (max-width: 767px) {
        padding-right: 18px;
      }
    }

    .MuiTablePagination-select {
      @media screen and (max-width: 767px) {
        padding-left: 4px;
        font-size: 0.75rem;
      }
    }

  }



}
