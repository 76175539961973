.multi-select {

  --rmsc-h: 31px !important;
  --rmsc-gray: rgba(0, 0, 0, 0.54) !important;

  .dropdown-container {
      border-bottom: 1px solid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;

      .dropdown-heading {

        span {
          color: rgba(0, 0, 0, 0.87);
          font-size: 1rem;
        }

      }

      label {
        position: relative;

        input[type="radio"], input[type="checkbox"] {
          position: absolute;
          top:15px;
        }

        span {
          padding-left: 20px;
        }

      }

  }

  .dropdown-container:focus-within {
    border-color: transparent;
  }

}

.multi-select + p.MuiFormHelperText-root {
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  top: 6px;
}
