/*Respuestas Styles*/

.MuiDialog-paperFullScreen {
  background-color: #F2F0EF !important;
}

.data-graphs {
  width: 100%;
  border-collapse: collapse;

  thead tr {
    background: #526069;
    color: #ffffff;
  }

  th, td {
      text-align: left;
      vertical-align: middle;
      padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f6f6f6;
  }

}

.graficos {

  .jr-card {

    .card-text-lists {
      background: #f9f9f9;
      padding: 10px;

      h5 {
        font-size: 1rem;
        font-weight: 400;
      }
    }

    .accordion {
        //box-shadow: none;

        .accordion-summary {
          background: #526069;
          color: #ffffff;

          svg {
            fill: #ffffff;
          }
        }

        .MuiExpansionPanelDetails-root {
          padding: 0;
        }

        .content-accordion {
          width: 100%;
          padding: 30px 30px;
        }

        .accordion-summary-comentarios {
          background: rgba(0, 0, 0, 0.05);
        }

        .content-accordion-comentarios {
          width: 100%;
          padding: 20px 20px;
        }

    }

    .card-text-respuesta-adicional {
      background: #f9f9f9;
      font-size: 0.8rem;
      font-weight: 300;
      padding: 10px;

      .subtitle-respuesta-adicional {
        font-weight: 400;
      }

    }

  }
}
