
.react-datepicker__header {
  background: #dc3545!important;
  padding: 5px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff!important;
  text-transform: capitalize;
}

.react-datepicker__day-names {
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color:#fff!important;
  }
}

.react-datepicker__navigation {
  color:#fff!important;
  top: 18px!important;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  color: #fff!important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #FF7E89!important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #FF7E89!important;
}

.react-datepicker__close-icon::after {
  background-color: #dc3545!important;
}

.react-datepicker__input-container {
  margin-top: 15px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  font-size: 1rem;

  input {
    border: 0;
    width: 100%;
    outline: 0;
    height: 32px;
  }
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 2rem !important;
  line-height: 2rem !important;
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.1rem !important;
}
