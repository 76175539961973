/*Inbox Styles*/
.inbox {

  .module-box-header-inner{

    .btn-aplicar-filtros {
      @media screen and (max-width: 414px) {
        width: 100%;
        margin-top: 0.5rem;;
      }
    }

    .btn-limpiar-filtros {
      @media screen and (max-width: 414px) {
        width: 100%;
        margin-top: 0.5rem;;
      }
    }
  }

}

.mail-detail {
  background: #f9fafb;

  .table-detail-inbox {
    width: 100%;
    border-collapse: collapse;

    thead tr {
      background: #526069;
      color: #ffffff;
    }

    th, td {
        text-align: left;
        vertical-align: middle;
        padding: 8px;
    }

    tr:nth-child(even) {
      background-color: #f6f6f6;
    }
  }

  .card-text-respuesta {
    padding: 10px;
    background: #F2F2F1;
    color: #000000;
  }

}

.content-comentarios {
  .item-comentario {
    background: #f6f6f6;
    padding: 10px;
  }

  .titulo {
    font-weight: 600;
  }

  .descripcion {
    color: #575757;
  }

  .fecha {
    font-size: 12px;
    font-weight: 500;;
  }
}

.form-comentario {

  .comentario {
    width: 600px;
    max-width: 100%;
  }

}