/*Cards Styles*/
/** Date Range Style **/

.modal-range-date-picker {
  .MuiDialog-paper {
    margin: 20px;

    .MuiDialogContent-root {
      padding: 8px 15px;
    }

  }
}

.rdrDateRangePickerWrapper {
  position: relative;

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrCalendarWrapper {
    font-size: 11px;

    .rdrDateDisplayWrapper {
      display: none;
    }
    
    .rdrMonthName {
      color: #000;
      font-weight: 400;
      text-transform: capitalize;
    }

    .rdrWeekDay {
      color: #000;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

}

/*.range-date-picker-content {
  position: absolute;
  z-index: 10;
  right: 0;
}*/

.range-date-picker-bar {

  .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0;

    .form-custom-control {
      border-radius: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      height: 40px;
      padding: 10px 18px 10px 40px;
      width: 100%;

      &:focus {
        outline: transparent;
        border:0;
        //background-color: $gray-100;
      }
    }

    .date-icon {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      right: 14px;
      background-color: transparent;
      border: 0 none;
      color: #868e96;
      top: 0;
      padding: 0;
      position: absolute;
    }

    .date-icon-dashboard {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      right: 14px;
      background-color: transparent;
      border: 0 none;
      color: #868e96;
      top: 24px;
      padding: 0;
      position: absolute;
    }

  }

}

.content-date-range-picker-no-edicion {

  .range-date-picker-bar {

    .form-group {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      .label-date-range-picker {
        color: rgba(0, 0, 0, 0.54);
        letter-spacing: 0.00938em;
        font-size: 0.75rem;
      }
  
      .form-custom-control {
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
        height: 35px;
        padding: 10px 18px 10px 40px;
        width: 100%;
  
        &:focus {
          outline: transparent;
          border:0;
          //background-color: $gray-100;
        }
      }
  
    }
  
  }

}

@media screen and (max-width: 599px) {

  .range-date-picker-bar { 
    .form-group {
    
      .date-icon {
        left: 15px;
      }

    }
  }

}

@media screen and (max-width: 375px) {

  .left-daterange-picker .rdrDateRangePickerWrapper {
  }
  
  .right-daterange-picker .rdrDateRangePickerWrapper {
  }

  .right-daterange-picker.no-edicion .rdrDateRangePickerWrapper {
  }
  
}