/*Respuestas Styles*/

.MuiDialog-paperFullScreen {
  background-color: #F2F0EF !important;
}

.background-respuestas {
  background: #F2F0EF;

  .jr-card {

    .card-text-respuesta {
      padding: 10px;
      background: #f2f2f2;
    }

    .titulo-opcion {

    }

    .card-text-respuesta-adicional {
      background: #f9f9f9;
      font-size: 0.8rem;
      font-weight: 300;
      padding: 10px;

      .subtitle-respuesta-adicional {
        font-weight: 400;
      }

    }

  }
}

.jr-card.filters {

  .multi-select {

    .dropdown-heading {
      padding: 0;
    }

  }

  .filtros-respuestas {

    .btn-filtros-respuestas {
      padding: 2px 5px;
    }
    
    .btn-filtros-respuestas.btn-danger {
      border: 1px solid #f44336;
      background: #ffffff;
      color: #f44336;
    }

    .btn-filtros-respuestas.btn-info {
      border: 1px solid #00bcd4;
      background: #ffffff;
      color: #00bcd4;
    }

    .main-btn-filtros  {
      
      @media screen and (max-width: 414px) {
        width: 100%;
        margin-top: 0.5rem;;
      }
    }

  }

}
